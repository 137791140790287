'use client';

import { node, object } from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';

import { Button, Form, Input, InputIcon, Label } from '@personly/libs-widgets';
import { useLocalization } from '@personly/libs-providers';
import { useLocalizedPathname } from '@personly/libs-hooks';
import { visibilityIcon } from '@personly/libs-icons';

const LoginForm = ({ children, languages }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const usernameRef = useRef();

  const localizedPathname = useLocalizedPathname(languages);
  const { translations } = useLocalization();

  useEffect(() => {
    if (usernameRef) usernameRef.current.focus();
  }, []);

  const isDisabled = useMemo(
    () => emailAddress.length === 0 || password.length === 0,
    [emailAddress.length, password.length]
  );

  return (
    <Form method="post" action="/agon/verifyAccount">
      {children}
      <div className="flex flex-col gap-4">
        <div>
          <Label for="username">
            {translations.formatMessage({ id: 'EMAIL_ADDRESS' })}
          </Label>
          <Input
            ref={usernameRef}
            type="email"
            placeholder={translations.formatMessage({ id: 'EMAIL_ADDRESS' })}
            name="username"
            id="username"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </div>
        <div>
          <Label for="password">
            {translations.formatMessage({ id: 'PASSWORD' })}
          </Label>
          <InputIcon
            icon={visibilityIcon(passwordVisible)}
            id="password"
            label="Password Visibility"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            onClick={() => setPasswordVisible(!passwordVisible)}
            placeholder={translations.formatMessage({ id: 'PASSWORD' })}
            type={passwordVisible ? 'text' : 'password'}
            value={password}
          />
          <div className={'text-right text-xs pt-1'}>
            <Link
              className="text-card-link-color"
              prefetch={false}
              href={localizedPathname('/forgot')}
            >
              {translations.formatMessage({ id: 'FORGOT_PASSWORD_QUESTION' })}
            </Link>
          </div>
        </div>
        <div>
          <Button block type="submit" disabled={isDisabled}>
            {translations.formatMessage({ id: 'LOGIN' })}
          </Button>
        </div>
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  children: node.isRequired,
  languages: object.isRequired,
};

export default LoginForm;
