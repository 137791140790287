import { bool, func, string } from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../icon';

const InputIcon = forwardRef(
  (
    {
      invalid,
      role = 'input',
      disabled = false,
      icon,
      label,
      onClick,
      ...props
    },
    ref
  ) => {
    const inputClassNames = [
      'text-input-text-color',
      'block',
      'h-10',
      'py-1.5',
      'px-2.5',
      'bg-clip-padding',
      'rounded-tl',
      'rounded-bl',
      'border-l',
      'border-y',
      'border-input-border-color',
      'focus:outline-0',
      'placeholder:text-input-placeholder-text-color',
      'relative',
      'flex-auto',
      'min-w-0',
    ];

    if (disabled) {
      inputClassNames.push('opacity-60');
      inputClassNames.push('outline-0');
    }

    if (invalid) {
      inputClassNames.push('!border-input-border-invalid-color');
      inputClassNames.push('focus:text-input-text-color');
      inputClassNames.push('focus:border-input-border-invalid-color)');
    }

    if (role === 'card') {
      inputClassNames.push('bg-card-background-color');
      inputClassNames.push('focus:!bg-card-background-color');

      if (invalid) {
        inputClassNames.push('focus:!bg-card-background-color');
      }
    } else if (role === 'input') {
      inputClassNames.push('bg-input-background-color');
      inputClassNames.push('focus:!bg-input-background-color');

      if (invalid) {
        inputClassNames.push('focus:!bg-input-background-color');
      }
    }

    const classNames = [
      'relative',
      'flex',
      'flex-wrap',
      'items-stretch',
      'w-full',
    ];

    if (role === 'card') classNames.push('bg-card-background-color');
    else if (role === 'input') classNames.push('bg-input-background-color');

    const buttonClassnames = [
      'bg-input-background-color',
      'rounded-tr',
      'rounded-br',
      'border-r',
      'border-y',
      'border-input-border-color',
      'text-input-icon-color',
      'px-3',
      'focus:outline-0',
    ];

    if (invalid) {
      buttonClassnames.push('!border-input-border-invalid-color');
    }

    return (
      <div className={classNames.join(' ')}>
        <input
          readOnly={disabled}
          autoComplete="off"
          ref={ref}
          className={inputClassNames.join(' ')}
          disabled={disabled}
          {...props}
        />
        <button
          aria-label={label}
          type="button"
          onClick={() => onClick()}
          className={buttonClassnames.join(' ')}
        >
          <Icon icon={icon} type="solid" size="1x" />
        </button>
      </div>
    );
  }
);

InputIcon.propTypes = {
  disabled: bool,
  invalid: bool,
  icon: string.isRequired,
  role: string,
  label: string.isRequired,
  onClick: func.isRequired,
};

InputIcon.displayName = 'InputIcon';

export default InputIcon;
